import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { IconLogoutLine } from '@assets/images/svg';
import { useApi } from '@hooks';
import { IUser } from '@typings';

import s from './ProfileMenu.scss';
import { ProfileMenuItem } from './ProfileMenuItem';


interface IProfileMenuProps {
    className?: string;
    currentUser: IUser;
    onLogout: () => Promise<void>;
}

export const ProfileMenu = (props: IProfileMenuProps) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const {
        className,
        onLogout,
    } = props;

    const {
        util,
    } = useApi();

    const navigate = useNavigate();

    const onExitClick = async () => {
        await onLogout();
        dispatch(util.resetApiState());
        localStorage.removeItem('token');
        navigate('/');
    };

    return (
        <div className={cn(s.profileMenu, className)}>
            <ProfileMenuItem icon={<IconLogoutLine/>} caption={t('common.logout')} onClick={onExitClick}/>
        </div>
    );
};
