import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

import { mergeObjectArrays } from '@common';
import { AppPage } from '@memealerts/views';
import { AuthRequired, routing as baseRouting } from '@routing';
import { Actions, Settings, Statistics, CommonMetrics, StickerSearchMetrics, StickerSendingLocations, Tts } from '@views';

export const memeAlertsRouting = mergeObjectArrays<RouteObject>(
    baseRouting,
    [
        {
            path: '/',
            element: <AuthRequired><AppPage/></AuthRequired>,
            children: [
                {
                    path: '',
                    element: <Navigate to={'statistics/common-metrics'}/>,
                },
                {
                    path: 'statistics',
                    element: <Statistics/>,
                    children: [
                        {
                            path: 'common-metrics',
                            element: <CommonMetrics/>,
                        },
                        {
                            path: 'sticker-search-metrics',
                            element: <StickerSearchMetrics/>,
                        },
                        {
                            path: 'sticker-sending-locations',
                            element: <StickerSendingLocations/>,
                        },
                    ],
                },
                {
                    path: 'settings',
                    element: <Settings/>,
                    children: [
                        {
                            path: 'common',
                            element: <div>Common</div>,
                        },
                        {
                            path: 'tts',
                            element: <Tts/>,
                        },
                        {
                            path: '',
                            element: <div>Common</div>,
                        },
                    ],
                },
                {
                    path: 'actions',
                    element: <Actions/>,
                },
            ],
        },
    ],
    'path',
);
