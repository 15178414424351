import { ICommonMetrics, IStickerSearchMetrics, IStickerSendingLocations } from '@typings';

import { baseApi } from './base.api';

export const statisticsApi = baseApi
    .enhanceEndpoints({
        addTagTypes: [
            'Statistics_DailyStatsPages',
            'Statistics_CurrentDailyStats',
            'Statistics_WeeklyStatsPages',
            'Statistics_CurrentWeeklyStats',
            'Statistics_MonthlyStatsPages',
            'Statistics_CurrentMonthlyStats',
            'Statistics_StickerSearchStatsPages',
            'Statistics_CurrentStickerSearchStats',
            'Statistics_StickerSendingLocationsPages',
            'Statistics_CurrentStickerSendingLocations',
        ],
    })
    .injectEndpoints({
        endpoints: (build) => ({
            loadDailyStatsPage: build.query<ICommonMetrics[], number>({
                query: (page: number) => ({
                    url: `stats/daily/${page}`,
                    method: 'GET',
                }),
                providesTags: ['Statistics_DailyStatsPages'],
            }),
            getCurrentDayStats: build.query<ICommonMetrics, void>({
                query: () => ({
                    url: `stats/daily/current`,
                    method: 'GET',
                }),
                providesTags: ['Statistics_CurrentDailyStats'],
            }),
            loadWeeklyStatsPage: build.query<ICommonMetrics[], number>({
                query: (page: number) => ({
                    url: `stats/weekly/${page}`,
                    method: 'GET',
                }),
                providesTags: ['Statistics_WeeklyStatsPages'],
            }),
            getCurrentWeekStats: build.query<ICommonMetrics, void>({
                query: () => ({
                    url: `stats/weekly/current`,
                    method: 'GET',
                }),
                providesTags: ['Statistics_CurrentWeeklyStats'],
            }),
            loadMonthlyStatsPage: build.query<ICommonMetrics[], number>({
                query: (page: number) => ({
                    url: `stats/monthly/${page}`,
                    method: 'GET',
                }),
                providesTags: ['Statistics_MonthlyStatsPages'],
            }),
            getCurrentMonthStats: build.query<ICommonMetrics, void>({
                query: () => ({
                    url: `stats/monthly/current`,
                    method: 'GET',
                }),
                providesTags: ['Statistics_CurrentMonthlyStats'],
            }),
            loadStickerSearchStatsPage: build.query<IStickerSearchMetrics[], number>({
                query: (page: number) => ({
                    url: `stats/search-daily/${page}`,
                    method: 'GET',
                }),
                providesTags: ['Statistics_StickerSearchStatsPages'],
            }),
            getCurrentStickerSearchStats: build.query<IStickerSearchMetrics, void>({
                query: () => ({
                    url: `stats/search-daily/current`,
                    method: 'GET',
                }),
                providesTags: ['Statistics_CurrentStickerSearchStats'],
            }),
            loadStickerSendingLocationsPage: build.query<IStickerSendingLocations[], number>({
                query: (page: number) => ({
                    url: `stats/sended-sticker-daily/${page}`,
                    method: 'GET',
                }),
                providesTags: ['Statistics_StickerSendingLocationsPages'],
            }),
            getCurrentStickerSendingLocations: build.query<IStickerSendingLocations, void>({
                query: () => ({
                    url: `stats/sended-sticker-daily/current`,
                    method: 'GET',
                }),
                providesTags: ['Statistics_CurrentStickerSendingLocations'],
            }),
            runFullRebuildStatistics: build.query<ICommonMetrics, void>({
                query: () => ({
                    url: `stats/full-rebuild`,
                    method: 'GET',
                }),
            }),
            runRebuildStatisticsFromDate: build.query<ICommonMetrics, number>({
                query: (fromdate: number) => ({
                    url: `stats/rebuild-from?fromDate=${fromdate}`,
                    method: 'GET',
                }),
            }),
        }),
    });
