import { Column, EComponentSize, ETheme, Label, Row, ThemeContext } from '@hyperclap/ui';
import React, { useContext, useState } from 'react';

import { Avatar } from '@components';
import { TApp } from '@hooks';
import { Logo, LogoKind } from '@memealerts/views';
import { ProfileMenu } from '@views';

import s from './Header.scss';

interface IHeaderProps extends TApp {}

export const Header = (props: IHeaderProps) => {
    const {
        currentUser: {
            currentUser,
            logout,
        },
        translation: {
            t,
        },
    } = props;

    const { theme } = useContext(ThemeContext);

    const [isMenuVisible, setIsMenuVisible] = useState(false);

    return (
        <Row grow columnGap={15} className={s.header}>
            <Logo width={70} kind={theme === ETheme.DARK ? LogoKind.DARK : LogoKind.LIGHT}/>
            <Column grow>
                <Label
                    margin={{ left: 20 }}
                    caption={t('common.admin-panel')}
                    size={EComponentSize.LARGE}
                    useBoldFont
                />
            </Column>
            <Column>
                {currentUser?.name}
            </Column>
            <Column>
                <Avatar
                    rounded
                    source={currentUser?.avatar}
                    onClick={() => setIsMenuVisible(!isMenuVisible)}
                    className={s.avatar}
                />

                { isMenuVisible && currentUser &&
                    <ProfileMenu currentUser={currentUser} onLogout={logout} className={s.profileMenu}/>
                }
            </Column>
        </Row>
    );
};
