import { useContext } from 'react';

import { AuthContext } from '@contexts';
import { useApi } from '@hooks';
import { useNavigator } from '@hooks/app/navigator';

export const useLogin = () => {
    const {
        goHome,
    } = useNavigator();
    const { updateToken, clearTokens } = useContext(AuthContext);
    const {
        auth: {
            useLazyLoginQuery,
        },
    } = useApi();
    const [postLogin] = useLazyLoginQuery();

    const logout = () => {
        clearTokens();
        goHome();
    };

    const login = async (username: string, password: string) => {
        if (username && password) {
            const loginResult = await postLogin({ username: username.toString(), password: password.toString() });

            if (loginResult.data?.token) {
                updateToken(loginResult.data?.token, loginResult.data?.refreshToken);
                goHome();
            } else {
                return false;
            }
        }
    };

    return {
        login,
        logout,
    };
};
