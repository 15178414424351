// TODO: Remove after testing

export enum CommonMetricsPeriod {
    DAY,
    WEEK,
    MONTH,
}

/**
 * Reflects a data structure that container all common metrics for MemeAlerts activities for certain periods
 */
export interface ICommonMetrics {
    // Period timestamp mark
    timestamp: number;

    // New registered users
    newUsers: number;

    // Total count of users
    totalUsers: number;

    // New supporters
    newSupporters: number;

    // Total supporters
    totalSupporters: number;

    // New users who pay to streamers
    newPayers: number;

    // Total payers count
    totalPayers: number;

    // Unique visitors of streamers pages (DAU, WAU, MAU)
    uniqueActiveUsers: number;

    // Unique payers count for certain period
    uniquePayers: number;

    // Unique users who earned welcome-bonus
    uniqueBonusHunters: number;

    // Unique senders for a certain period
    uniqueSenders: number;

    // Unique Payers percentage (% of uniqueActiveUsers)
    uniquePayersP: number;

    // Bonus Hunters percentage (% of uniqueActiveUsers)
    bonusHuntersP: number;

    // Senders percents (% of uniqueActiveUsers)
    uniqueSendersP: number;

    // New streamers for a certain period
    newStreamers: number;

    // Total streamers count
    totalStreamers: number;

    // Count of streamers that was online during the period (DOS, WOS, MOS)
    onlineStreamers: number;

    // Count of streamers that received at least one sticker during the period (DAS, WAS, MAS)
    activeStreamers: number;

    // Count of streamers that has earnings (users have bought currency from them) (DES, WES, MES)
    earningStreamers: number;

    // Active Streamers percentage
    activeStreamersP: number;

    // Count of the send sticker popup openings
    stickersOpened: number;

    // Count of stickers sends
    stickersSent: number;

    // Sends ratio (stickerSent / stickerOpen)
    sentRatio: number;

    // Count of audio only stickers
    audioStickers: number;

    // Count of voiced stickers
    voicedStickers: number;

    // Count of fullscreen stickers
    fullScreenStickers: number;

    // Count of meme cannon stickers
    memeCannonStickers: number;

    // Total count of stickers that were pre-moderated by AI
    totalPreModerated: number;

    // Passed stickers count
    openAiPassed: number;

    // Banned stickers count
    openAiBanned: number;

    // Banned by streamer settings count
    openAiBannedByStreamer: number;

    // Declined as outdated
    declinedByTimeout: number;

    // Audio Stickers percentage
    audioStickersP: number;

    // Voiced Stickers percentage
    voicedStickersP: number;

    // Fullscreen Stickers percentage
    fullscreenStickersP: number;

    // Meme Cannon Stickers percentage
    memeCannonStickersP: number;

    // Passed stickers percent
    openAiPassedP: number;

    // Banned stickers percent
    openAiBannedP: number;

    // Banned by streamer settings percent
    openAiBannedByStreamerP: number;

    // Average count of stickers per sender (stickersSent / uniqueSenders)
    stickersPerSender: number;

    // Count of meme parties
    memePartiesCount: number;

    // Value of purchased currency (in coins)
    purchasedCurrency: number;

    // Value of earned bonuses (in coins)
    bonusesCount: number;

    // Value of spent currency (in coins)
    spentCurrency: number;

    // Percent of currency that was spent for usual stickers
    spentCurrencyForStickersP: number;

    // Percent of currency that was spent for fullscreen stickers
    spentCurrencyForFullScreensP: number;

    // Percent of currency that was spent for meme cannon stickers
    spentCurrencyForMemeCannonP: number;

    // Percent of currency that was spent for voicing stickers
    spentCurrencyForVoicingP: number;

    // Average revenue per an active streamer (Revenue / Active Streamers)
    averageRevenuePerActiveStreamer: number;

    // Average revenue per a payer (Revenue / Unique Payers)
    averageRevenuePerPayer: number;

    // Average revenue for a sticker (Revenue / Stickers Sent)
    averageRevenuePerSticker: number;

    // Revenue
    revenue: number;

    // Profit
    profit: number;

    // Period that common metrics was built for
    period: CommonMetricsPeriod;
}
