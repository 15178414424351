import React from 'react';

import { DATE_FORMAT_LOCALE, getWeek, getWeekYear, timestampToMonth, timestampToShortDate } from '@common';
import { StatisticsMode } from '@hooks';
import { ICommonMetrics } from '@typings';


interface StatisticRecordProps {
    data: ICommonMetrics;
    statisticsMode: StatisticsMode;
}

function buildTimestampView(timestamp: number, statisticsMode: StatisticsMode) {
    switch (statisticsMode) {
        case StatisticsMode.DAILY: {
            return timestampToShortDate(timestamp);
        }

        case StatisticsMode.WEEKLY: {
            return getWeek(timestamp) + '/' + getWeekYear(timestamp);
        }

        case StatisticsMode.MONTHLY: {
            return timestampToMonth(timestamp);
        }
    }
}

const numberFormatter = new Intl.NumberFormat(DATE_FORMAT_LOCALE, {
    style: 'decimal',
    maximumFractionDigits: 0,
});

const numberFormatterDec = new Intl.NumberFormat(DATE_FORMAT_LOCALE, {
    style: 'decimal',
    maximumFractionDigits: 2,
});

const percentFormatter = new Intl.NumberFormat(DATE_FORMAT_LOCALE, {
    style: 'percent',
    maximumFractionDigits: 2,
});

export const StatisticsRecord = (props: StatisticRecordProps) => {
    const {
        data,
        statisticsMode,
    } = props;


    return (
        <tr>
            <td>
                {buildTimestampView(data.timestamp, statisticsMode)}
            </td>
            <td>
                {numberFormatter.format(data.newUsers)}
            </td>
            <td>
                {numberFormatter.format(data.totalUsers)}
            </td>
            <td>
                {numberFormatter.format(data.newSupporters)}
            </td>
            <td>
                {numberFormatter.format(data.totalSupporters)}
            </td>
            <td>
                {numberFormatter.format(data.newPayers)}
            </td>
            <td>
                {numberFormatter.format(data.totalPayers)}
            </td>
            <td>
                {numberFormatter.format(data.uniqueActiveUsers)}
            </td>
            <td>
                {numberFormatter.format(data.uniquePayers)}
            </td>
            <td>
                {numberFormatter.format(data.uniqueBonusHunters)}
            </td>
            <td>
                {numberFormatter.format(data.uniqueSenders)}
            </td>
            <td>
                {percentFormatter.format(data.uniquePayersP / 100)}
            </td>
            <td>
                {percentFormatter.format(data.bonusHuntersP / 100)}
            </td>
            <td>
                {percentFormatter.format(data.uniqueSendersP / 100)}
            </td>
            <td>
                {numberFormatter.format(data.newStreamers)}
            </td>
            <td>
                {numberFormatter.format(data.totalStreamers)}
            </td>
            <td>
                {numberFormatter.format(data.onlineStreamers)}
            </td>
            <td>
                {numberFormatter.format(data.activeStreamers)}
            </td>
            <td>
                {numberFormatter.format(data.earningStreamers)}
            </td>
            <td>
                {percentFormatter.format(data.activeStreamersP)}
            </td>
            <td>
                {numberFormatter.format(data.stickersOpened)}
            </td>
            <td>
                {numberFormatter.format(data.stickersSent)}
            </td>
            <td>
                {numberFormatterDec.format(data.sentRatio)}
            </td>
            <td>
                {numberFormatter.format(data.audioStickers)}
            </td>
            <td>
                {numberFormatter.format((data.voicedStickers ?? 0))}
            </td>
            <td>
                {numberFormatter.format(data.fullScreenStickers)}
            </td>
            <td>
                {numberFormatter.format(data.memeCannonStickers)}
            </td>
            <td>
                {percentFormatter.format(data.audioStickersP / 100)}
            </td>
            <td>
                {percentFormatter.format((data.voicedStickersP ?? 0) / 100)}
            </td>
            <td>
                {percentFormatter.format(data.fullscreenStickersP / 100)}
            </td>
            <td>
                {percentFormatter.format(data.memeCannonStickersP / 100)}
            </td>
            {/* ======= */}
            <td>
                {numberFormatter.format(data.openAiPassed ?? 0)}
            </td>
            <td>
                {numberFormatter.format(data.openAiBanned ?? 0)}
            </td>
            <td>
                {numberFormatter.format(data.openAiBannedByStreamer ?? 0)}
            </td>
            <td>
                {numberFormatter.format(data.declinedByTimeout ?? 0)}
            </td>
            {/* ======= */}
            <td>
                {numberFormatter.format(data.stickersPerSender)}
            </td>
            <td>
                {numberFormatter.format(data.memePartiesCount)}
            </td>
            <td>
                {numberFormatter.format(data.purchasedCurrency)}
            </td>
            <td>
                {numberFormatter.format(data.bonusesCount)}
            </td>
            <td>
                {numberFormatter.format(data.spentCurrency)}
            </td>
            <td>
                {percentFormatter.format(data.spentCurrencyForStickersP / 100)}
            </td>
            <td>
                {percentFormatter.format(data.spentCurrencyForFullScreensP / 100)}
            </td>
            <td>
                {percentFormatter.format(data.spentCurrencyForMemeCannonP / 100)}
            </td>
            <td>
                {percentFormatter.format((data.spentCurrencyForVoicingP ?? 0) / 100)}
            </td>
            <td>
                {numberFormatter.format(data.averageRevenuePerActiveStreamer)}
            </td>
            <td>
                {numberFormatter.format(data.averageRevenuePerPayer)}
            </td>
            <td>
                {numberFormatterDec.format(data.averageRevenuePerSticker)}
            </td>
            <td>
                {numberFormatter.format(data.revenue)}
            </td>
            <td>
                {numberFormatter.format(data.profit)}
            </td>
        </tr>
    );
};
