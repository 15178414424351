export interface IStickerSearchMetrics {
    day: number;

    // Total searches
    search: number;

    // Unsuccessful searches
    searchNull: number;

    // Sends from search results
    searchSent: number;
}
