import { Button, Column, EComponentColor, EComponentSize, Label, Row, TextField, useLogger } from '@hyperclap/ui';
import React, { useState } from 'react';

import { TApp } from '@hooks';

import s from './Desktop.scss';

interface IDesktopProps extends TApp {
}

export const Desktop = (props: IDesktopProps) => {
    const logger = useLogger({ target: 'Actions/State', showTimestamp: true });

    const {
        statistics: {
            commonMetrics: {
                runFullRebuildQuery,
                runRebuildStatisticsFromDate,
            },
        },
    } = props;

    const [fromDate, setFromDate] = useState<number>(0);

    const SetSelectedDate = (v: string) => {
        try {
            const d = new Date(Date.parse(v));
            setFromDate(d.setHours(0, 0, 0, 0));
        } catch (ex) {
            logger.error(ex);
        }
    };

    return (
        <Column padding={20} grow className={s.view}>
            <Row columnGap={10} margin={{ bottom: 20 }}>
                <Label caption={'Statistics'} size={EComponentSize.LARGE}/>
            </Row>

            <Row columnGap={10} margin={{ bottom: 20 }}>
                <Button width={180} color={EComponentColor.INFO} caption={'Full Stats Rebuild'} onClick={() => runFullRebuildQuery()}/>
            </Row>

            <Row columnGap={10} margin={{ bottom: 20 }}>
                <Button
                    width={180}
                    color={EComponentColor.TWITCH}
                    caption={'Rebuild Stats From:'}
                    disabled={!fromDate}
                    onClick={() => runRebuildStatisticsFromDate(fromDate)}
                />
                <TextField
                    width={150}
                    height={40}
                    type={'date'}
                    onChanged={SetSelectedDate}
                />
            </Row>
        </Column>
    );
};
