import { DATE_FORMAT_LOCALE, DATE_FORMAT_MONTH, DATE_FORMAT_SHORT } from '@common';

export const timestampToShortDate = (timestamp: number) => {
    return new Date(timestamp).toLocaleDateString(DATE_FORMAT_LOCALE, DATE_FORMAT_SHORT);
};

export const timestampToMonth = (timestamp: number) => {
    return new Date(timestamp).toLocaleDateString(DATE_FORMAT_LOCALE, DATE_FORMAT_MONTH);
};

export function getFirstDayOfMonth(timestamp: number) {
    const date = new Date(timestamp);

    return new Date(date.getFullYear(), date.getMonth(), 1).getTime();
}

export function isLastDayOfMonth(timestamp: number) {
    return new Date(timestamp + 86400000).getDate() === 1;
}

export function getWeek(timestamp: number) {
    const date = new Date(timestamp);
    date.setHours(0, 0, 0, 0);
    // Thursday in current week decides the year.
    date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
    // January 4 is always in week 1.
    const week1 = new Date(date.getFullYear(), 0, 4);

    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + ((week1.getDay() + 6) % 7)) / 7);
}

export function getWeekYear(timestamp: number) {
    const date = new Date(timestamp);
    date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));

    return date.getFullYear();
}
