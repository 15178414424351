import React from 'react';

import { DATE_FORMAT_LOCALE, timestampToShortDate } from '@common';
import { IStickerSearchMetrics } from '@typings';


interface StatisticRecordProps {
    data: IStickerSearchMetrics;
}

function buildTimestampView(timestamp: number) {
    return timestampToShortDate(timestamp);
}

const numberFormatter = new Intl.NumberFormat(DATE_FORMAT_LOCALE, {
    style: 'decimal',
    maximumFractionDigits: 0,
});

const percentFormatter = new Intl.NumberFormat(DATE_FORMAT_LOCALE, {
    style: 'percent',
    maximumFractionDigits: 2,
});

export const StatisticsRecord = (props: StatisticRecordProps) => {
    const {
        data,
    } = props;


    return (
        <tr>
            <td>
                {buildTimestampView(data.day)}
            </td>
            <td>
                {numberFormatter.format(data.search)}
            </td>
            <td>
                {numberFormatter.format(data.searchNull)}
            </td>
            <td>
                {numberFormatter.format(data.searchSent)}
            </td>
            <td>
                {percentFormatter.format(data.searchNull / (data.search || 1))}
            </td>
            <td>
                {percentFormatter.format(data.searchSent / (data.search || 1))}
            </td>
        </tr>
    );
};
