import { authApi } from './auth.api';
import { baseApi } from './base.api';
import { settingsApi } from './settings.api';
import { statisticsApi } from './statistics.api';
import { usersApi } from './users.api';

export const api = {
    ...baseApi,
    auth: { ...authApi },
    settings: { ...settingsApi },
    statistics: { ...statisticsApi },
    users: { ...usersApi },
};
