import { useEffect, useState } from 'react';

import { api } from '@store';
import { IStickerSendingLocations } from '@typings';

export const useStickerSendingLocations = () => {
    const {
        statistics: {
            useLazyLoadStickerSendingLocationsPageQuery,
            useGetCurrentStickerSendingLocationsQuery,
        },
    } = api;

    const [page, setPage] = useState(1);
    const [isEndReached, setEndReached] = useState(false);
    const [statistic, setStatistic] = useState<IStickerSendingLocations[]>([]);

    const {
        data: currentStats,
    } = useGetCurrentStickerSendingLocationsQuery();

    const [
        loadPage,
        {
            data: loadedPage,
            isLoading: isStatisticLoading,
        },
    ] = useLazyLoadStickerSendingLocationsPageQuery();

    const loadNextPage = () => {
        setPage(page + 1);
    };

    useEffect(() => {
        loadPage(page);
    }, []);

    useEffect(() => {
        loadPage(page);
    }, [page]);

    useEffect(() => {
        if (loadedPage && !loadedPage.length) {
            setEndReached(true);
        }

        setStatistic((prev) => [...prev].concat(loadedPage ?? []));
    }, [loadedPage]);

    return {
        currentStats,
        statistic,
        isStatisticLoading,
        isEndReached,
        loadNextPage,
    };
};
