import React from 'react';

import { useAuth } from '@hooks';

export const AuthRequired = (props: React.PropsWithChildren) => {
    const { children } = props;
    const { authToken, redirectUnauthorizedTo } = useAuth();

    return authToken
        ? children
        : redirectUnauthorizedTo;
};
