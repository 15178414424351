export interface IStickerSendingLocations {
    day: number;

    // Total count
    stickerSent: number;

    // From Search view
    search: number;

    // From Top view
    top: number;

    // From Popular view
    popular: number;

    // From Last view
    last: number;

    // From Channel view
    channel: number;

    // From Favorites view
    favorites: number;

    // From My view
    myCreated: number;
}
