import {
    Button,
    Column,
    Divider,
    EComponentSize,
    ERowAlignment,
    ERowJustification,
    Label,
    Row,
    ScrollableArea,
} from '@hyperclap/ui';
import React from 'react';

import { TApp } from '@hooks';

import s from './Desktop.scss';
import { StatisticsRecord } from '../../components';

interface IDesktopProps extends TApp {}

export const Desktop = (props: IDesktopProps) => {
    const {
        statistics: {
            stickerSearchMetrics: {
                currentStats,
                statistic,
                isEndReached,
                loadNextPage,
            },
        },
        translation: {
            t: translate,
        },
    } = props;

    const t = (text: string) => translate(`app.statistics.${text}`);

    const rows = statistic?.map((r, idx) => (
        <StatisticsRecord data={r} key={idx}/>
    ));

    const onNextPageClick = () => {
        loadNextPage();
    };

    return (
        <Column grow padding={10} className={s.view}>
            <Row columnGap={15} justification={ERowJustification.SPACE_BETWEEN}>
                <Column>
                    <Label caption={t('title')} size={EComponentSize.MEDIUM} useBoldFont/>
                </Column>
                <Column>
                    <Button
                        caption={t('load-more')}
                        disabled={isEndReached}
                        onClick={onNextPageClick}
                    />
                </Column>
            </Row>

            <Divider/>

            <Row grow alignment={ERowAlignment.TOP}>
                <ScrollableArea className={s.scrollableContainer} contentClassName={s.scrollableContainerContent}>
                    <table className={s.table}>
                        <thead>
                            <tr>
                                <th>
                                    {'Date'}
                                </th>
                                <th>
                                    {'Total Sent'}
                                </th>
                                <th>
                                    {'Search Null'}
                                </th>
                                <th>
                                    {'Search Sent'}
                                </th>
                                <th>
                                    {'Search Null %'}
                                </th>
                                <th>
                                    {'Search Sent %'}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentStats &&
                                <StatisticsRecord data={currentStats} key={-1}/>
                            }

                            {rows}
                        </tbody>
                    </table>
                </ScrollableArea>
            </Row>
        </Column>
    );
};
